<template>
  <div id="genesys-container">

  </div>
</template>
<script>
import {GENESYS_API_KEY} from "@/config";
import featureFlagService from "@/services/featureFlagService"
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      featureFlags: []
    }
  },
  methods: {
    attachGenesysChat(g, e, n, es, ys) {
      if (!g['_genesysJs']) {
        g['_genesysJs'] = e;
        g[e] = g[e] || function () {
          (g[e].q = g[e].q || []).push(arguments)
        };
        g[e].t = 1 * new Date();
        g[e].c = es;
        ys = document.createElement('script');
        ys.async = 1;
        ys.src = n;
        ys.charset = 'utf-8';
        document.getElementById('genesys-container').appendChild(ys);
      } else {
        document.getElementById('genesys-messenger').style.display = 'initial';
      }
    },
    checkFeatureFlags() {
      if (featureFlagService.isAccessible(this.featureFlags,'genesysChat')) {
        this.attachGenesysChat(window, 'Genesys', 'https://apps.mypurecloud.com/genesys-bootstrap/genesys.min.js', {
          environment: 'prod',
          deploymentId: GENESYS_API_KEY
        });
      }
    }
  },
  computed: {
    ...mapGetters('featureFlagModule', ['getFeatureFlags']),
  },
  watch: {
    getFeatureFlags(newFlags) {
      this.featureFlags = newFlags;
      this.checkFeatureFlags()
    }
  },
  mounted() {
    this.featureFlags = this.getFeatureFlags;
    this.checkFeatureFlags()
  }
}
</script>
