import { API_URL, DEAFULT_API_VERSION } from './config';
import axios from 'axios';
import router from './router';
import { store } from './store';

const axiosInstance = axios.create({
  // default axios configurations
  baseURL: API_URL,
  withCredentials: true,
  // timeout: 3000,
});

// intercept responses and check for authn/authz
// force logoff, if 401 or 403 received
axiosInstance.interceptors.response.use(function (res) {
  return res
}, function (error) {
  // TODO: should look into improving this process as to not lead to multiple IFs
  if (error?.response?.config?.url === '/v1/self-service-email-login') return Promise.reject(error)

  if (error.response.status === 401 || error.response.status === 403) {
    store.dispatch('forceLogoff')
    router.push('/signin')
  }
  return Promise.reject(error)
})

const Base = {
  apiVersion: (version = DEAFULT_API_VERSION) => `/${version}`,
};

const API = {
  getFeatureFlags: () => {
    return `${Base.apiVersion()}/config/feature-flags`
  },
  getStates: () => {
    return `${Base.apiVersion()}/pagecontrols/users/details`;
  },
  getStateByAbbreviation: (state) => {
    return `${Base.apiVersion()}/pagecontrols/states/${state}`
  },
  getAgencies: () => {
    return `${Base.apiVersion()}/agencies`;
  },
  getRoles: () => {
    return `${Base.apiVersion()}/pagecontrols/users/details`;
  },
  getEnrollments: () => {
    return `${Base.apiVersion()}/enrollments`;
  },
  getEnrollment: (id) => {
    return `${Base.apiVersion()}/enrollments/${id}`;
  },
  getEnrollmentStatus: () => {
    return `${Base.apiVersion()}/pagecontrols/enrollments`;
  },
  archiveEnrollment: (id) => {
    return `${Base.apiVersion()}/enrollments/${id}`;
  },
  removeNhepStatus: (id) => {
    return `${Base.apiVersion()}/enrollments/${id}/nhep`
  },
  getEnrollees: (id) => {
    return `${Base.apiVersion()}/enrollees/${id}`;
  },
  getEnrolleesList: (id) => {
    return `${Base.apiVersion()}/enrollees/?enrollmentId=${id}`;
  },
  getPackets: () => {
    return `${Base.apiVersion()}/packets`;
  },
  getPacketsById: () => {
    return `${Base.apiVersion()}/packets`;
  },
  getPacket: (id) => {
    return `${Base.apiVersion()}/packets/${id}`;
  },
  createPacket: () => {
    return `${Base.apiVersion()}/packets`;
  },
  updatePacket: (id) => {
    return `${Base.apiVersion()}/packets/${id}`;
  },
  getDocuments: () => {
    return `${Base.apiVersion()}/documents`;
  },
  getDocumentsByEnrollee: (id) => {
    return `${Base.apiVersion()}/enrollee/documents/${id}`
  },
  createDocument: () => {
    return `${Base.apiVersion()}/documents`;
  },
  updateDocument: (id) => {
    return `${Base.apiVersion()}/documents/${id}`;
  },
  getUsers: () => {
    return `${Base.apiVersion()}/user`;
  },
  getUser: (id) => {
    return `${Base.apiVersion()}/user/${id}`;
  },
  updateUser: (id) => {
    return `${Base.apiVersion()}/user/${id}`;
  },
  getLanguages: () => {
    return `${Base.apiVersion()}/pagecontrols/users/details`;
  },
  archiveDocument: (id) => {
    return `${Base.apiVersion()}/documents/${id}`;
  },
  archivePacket: (id) => {
    return `${Base.apiVersion()}/packets/${id}`;
  },
  addAgenciesToPacket: (id) => {
    return `${Base.apiVersion()}/packet/agencies/${id}`;
  },
  getAgenciesByPacketId: (id) => {
    return `${Base.apiVersion()}/packet/agencies/${id}`;
  },
  deleteAgencyFromPacket: (id) => {
    return `${Base.apiVersion()}/packet/agencies/${id}`;
  },
  getAgencyListForPackets: () => {
    return `${Base.apiVersion()}/packet/agencies`;
  },
  setDefaultPacket: (id) => {
    return `${Base.apiVersion()}/packet/agencies/${id}/default`;
  },
  updateDocumentOrder: () => {
    return `${Base.apiVersion()}/packet/documents/sort`;
  },
  deleteDocumentFromPacket: (id) => {
    return `${Base.apiVersion()}/packet/documents/${id}`;
  },
  assignDocumentToPacket: (id) => {
    return `${Base.apiVersion()}/packet/documents/${id}`;
  },
  assignDocumentToEnrollee: (id) => {
    return `${Base.apiVersion()}/enrollee/documents/${id}`;
  },
  addNotes: () => {
    return `${Base.apiVersion()}/notes/`;
  },
  getNotes: () => {
    return `${Base.apiVersion()}/notes/`;
  },
  getAgentList: () => {
    return `${Base.apiVersion()}/available-agent`;
  },
  getParticipantNotes: () => {
    return `${Base.apiVersion()}/notes`;
  },
  updateEnrollee: (id) => {
    return `${Base.apiVersion()}/enrollments/presentation/${id}`;
  },
  getRace: () => {
    return `${Base.apiVersion()}/pagecontrols/races`;
  },
  getCaregiverRelationshipTypes: () => {
    return `${Base.apiVersion()}/pagecontrols/caregiver/relationship-types`;
  },
  getCaregiverLegalResponsibilityTypes: () => {
    return `${Base.apiVersion()}/pagecontrols/caregiver/legal/responsibility-types`;
  },
  getCaregiverResponsibilityTypes: () => {
    return `${Base.apiVersion()}/pagecontrols/caregiver/responsibility-types`;
  },
  saveParticipant: (id) => {
    return `${Base.apiVersion()}/enrollees/${id}`;
  },
  saveCaregiver: (id) => {
    return `${Base.apiVersion()}/enrollees/${id}`;
  },
  addNewCaregiver: () => {
    return `${Base.apiVersion()}/enrollees`
  },
  updateStatusAndScheduleType: () => {
    return `${Base.apiVersion()}/enrollments/`;
  },
  updateAssignment: (id) => {
    return `${Base.apiVersion()}/enrollment/specialist/${id}`;
  },
  saveVendor: (id) => {
    return `${Base.apiVersion()}/enrollees/${id}`;
  },
  assignPacket: (id) => {
    return `${Base.apiVersion()}/enrollee/${id}/packet`;
  },
  getCaregivers: (id) => {
    return `${Base.apiVersion()}/enrollees/caregiver/${id}`;
  },
  assignCaregiversToEnrollment: (id) => {
    return `${Base.apiVersion()}/enrollees/caregiver/${id}`;
  },
  scheduleAppointment: () => {
    return `${Base.apiVersion()}/calendar/create-event`;
  },
  fetchAgentCalendarEvents: () => {
    return `${Base.apiVersion()}/calendar/get-events`;
  },
  createBulkCalendarEvents: () => {
    return `${Base.apiVersion()}/calendar/bulk-create`
  },
  uploadDocuments: () => {
    return `${Base.apiVersion()}/enrollee/documents`;
  },
  removeEnrolleeDocuments: (id) => {
    return `${Base.apiVersion()}/enrollee/documents/${id}`;
  },
  rescheduleAppointment: () => {
    return `${Base.apiVersion()}/calendar/delete-events`;
  },
  getDocumentCustomQuestions: (enrolleeId, documentId) => {
    return `${Base.apiVersion()}/enrollees/${enrolleeId}/documents/${documentId}/prompts`;
  },
  saveCustomPromptResponse: (enrolleeId, documentId) => {
    return `${Base.apiVersion()}/enrollees/${enrolleeId}/documents/${documentId}/responses`;
  },
  setSelfService: () => {
    return `${Base.apiVersion()}/update-self-service`;
  },
  sendEmails: () => {
    return `${Base.apiVersion()}/send-emails`;
  },
  sendCaregiverEmails: () => {
    return `${Base.apiVersion()}/send-caregiver-emails`;
  },
  sendEmail: () => {
    return `${Base.apiVersion()}/send-email`;
  },
  sendEmailsMass: (type) => `${Base.apiVersion()}/send-emails-mass/${type}`,
  verifyEnrolleeDetails: () => {
    return `${Base.apiVersion()}/self-service-email-login`;
  },
  fetchUserInfo: () => {
    return `${Base.apiVersion()}/user-info`
  },

  removeEnrolleeFromEnrollment: (id) => {
    return `${Base.apiVersion()}/enrollees/${id}`;

  },

  enrollmentParticpant: (enrollmentId) => {
    return `${Base.apiVersion()}/enrollments/${enrollmentId}/participant`;
  },

  returnEnrollmentToActive: (enrollmentId) => {
    return `${Base.apiVersion()}/enrollment/activate/${enrollmentId}`
  },

  getEmployerRelationship: () => {
    return `${Base.apiVersion()}/config/relationships/`;
  },

  // getEnrollmentProgress: (enrollmentId) => {
  //   return;
  // },

  getEnrolleeEnrollmentProgress: (enrolleeId) => {
    return `${Base.apiVersion()}/enrollee/${enrolleeId}/progress`
  },

  updateEnrollmentProgress: () => {
    return `${Base.apiVersion()}/enrollee/setComplete`;
  },

  samlLogin: () => `${Base.apiVersion()}/saml/login`,
  logout: () => `${Base.apiVersion()}/logout`,
  userCheck: () => `${Base.apiVersion()}/user-check`,

  parsePdfDocument: () => `${Base.apiVersion()}/documents/parse`,

  docusignGetConsoleView: () => `${Base.apiVersion()}/docusign/console-view`,
  docusignGetSenderView: (envelopeId) => `${Base.apiVersion()}/docusign/sender-view/${envelopeId}`,
  docusignGetRecipientView: (envelopeId) => `${Base.apiVersion()}/docusign/recipient-view/${envelopeId}`,
  docusignCreateEnvelope: () => `${Base.apiVersion()}/docusign/envelope`,
  docusignEmbedSignType: (enrolleeId) => `${Base.apiVersion()}/docusign/sign-type/${enrolleeId}`,
  docusignCallbackUrl: (envelopeData) => `${API_URL}${Base.apiVersion()}/docusign/callback/${envelopeData}`,
  docusignAuthCodeReset: () => `${Base.apiVersion()}/docusign/auth-code`,
  nullifyDocusignForEnrollee: (enrolleeId) => `${Base.apiVersion()}/docusign/nullify/${enrolleeId}`,

  confirmCreate: () => `${Base.apiVersion()}/confirm`,
  getConfirmResponseById: (id) => `${Base.apiVersion()}/confirm/${id}`,
  checkEnrolleeExists: (id, state) => `${Base.apiVersion()}/confirm/${id}/exists?state=${state}`,
  getConfirmConfigurations: (state_abbreviation) => `${Base.apiVersion()}/confirm/config/${state_abbreviation}`,

  fetchAzurePhotoById: (usersId) => '/api' + `${Base.apiVersion()}/azure-photo/${usersId}`,
};

const apiErrorHandler = (error) => { console.error(error); throw error; }

export { axiosInstance as axios, API, apiErrorHandler };
